'use client';
import Link from 'next/link';
import Image from 'next/image';
import { type RefObject } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import getDocumentOptions from 'lib/getDocumentOptions';
import { medicationTypeInjection, medicationTypeOralPill } from 'assets/icons';

export interface subscriptionCardProps {
  subscriptionTitle: string;
  medicationType: string;
  pricing: string;
  ctaText: string;
  ctaLink: string;
  bodyCopy: {
    json: any;
  };
  learnMoreText: string;
  learnMoreLink: string;
}

interface SubscriptionProps {
  title: string;
  subscriptionCards: subscriptionCardProps[];
  subscriptionRef: RefObject<HTMLDivElement>;
}

const Subscription = ({ title, subscriptionCards, subscriptionRef }: SubscriptionProps) => {
  return (
    <div ref={subscriptionRef} className=" bg-arctic-200 pb-4 pt-24 lg:mt-0">
      <section className="mx-4 flex max-w-5xl flex-col items-center gap-10 px-6 lg:mx-auto lg:px-4">
        <div>
          <h2 className="text-center text-4xl font-medium lg:text-3xl lg:font-normal">{title}</h2>
        </div>
        <div className="flex flex-col items-center justify-center gap-8 lg:flex-row lg:flex-wrap lg:gap-7">
          {subscriptionCards.map(
            (
              {
                medicationType,
                subscriptionTitle,
                pricing,
                bodyCopy,
                learnMoreLink,
                learnMoreText,
                ctaText,
                ctaLink,
              },
              index,
            ) => (
              <div
                key={`card-${index}`}
                className="flex w-full max-w-[265px] flex-col items-center gap-8 rounded-lg border border-orange-900 bg-white px-4 py-6 lg:w-fit lg:gap-5 lg:px-4 lg:py-5"
              >
                <div className="flex flex-col items-center gap-6 lg:gap-5">
                  <div className="flex gap-1">
                    <Image
                      alt={'Program type icon'}
                      className="fill-orange-900"
                      height={18}
                      src={
                        medicationType === 'Injection'
                          ? medicationTypeInjection
                          : medicationTypeOralPill
                      }
                      width={18}
                    />
                    <p className="text-sm font-medium text-orange-900 lg:text-xs lg:font-normal">
                      {medicationType}
                    </p>
                  </div>
                  <p className="px-6 text-center text-2xl font-bold text-black-800 lg:text-xl lg:font-semibold">
                    {subscriptionTitle}
                  </p>
                </div>
                <div className="flex w-full flex-col items-center gap-4 lg:gap-3">
                  <p className="text-base font-normal uppercase text-teal-900 lg:text-sm lg:font-light">
                    <span className="font-noto-serif text-4xl font-semibold lg:text-3xl lg:font-medium">
                      {pricing}
                    </span>
                    /month
                  </p>
                  <Link
                    className="inline-block w-full rounded-[4px] border border-teal-900 bg-arctic-900 px-6 py-1 text-center 
                        text-[12px] font-bold uppercase tracking-wide text-teal-900 transition-all 
                        ease-in-out hover:-translate-y-0.5 hover:border-2 hover:bg-sky-900 lg:font-semibold"
                    href={ctaLink}
                    target="_blank"
                  >
                    {ctaText}
                  </Link>
                </div>
                <div className="flex flex-col items-center gap-4 lg:gap-3">
                  <div className="text-sm text-black-800 lg:text-xs lg:font-light [&_:is(ul)]:pl-4">
                    {bodyCopy?.json &&
                      documentToReactComponents(bodyCopy.json, getDocumentOptions())}
                  </div>
                  <Link
                    className="text-sm font-bold text-teal-900 underline lg:text-xs lg:font-semibold"
                    href={learnMoreLink}
                    target="_blank"
                  >
                    {learnMoreText}
                  </Link>
                </div>
              </div>
            ),
          )}
        </div>
      </section>
    </div>
  );
};

export default Subscription;
